export enum TagType {
  Assessment = 'ASSESSMENT',
  AssessmentCategories = 'ASSESSMENT_CATEGORIES',
  AssessmentCategoryEvidenceType = 'ASSESSMENT_CONTROL_POINT_EVIDENCE_TYPE',
  AssessmentControlPointAnswerTypeFilter = 'ASSESSMENT_CONTROL_POINT_ANSWER_TYPE_FILTER',
  AssessmentControlPointList = 'ASSESSMENT_CONTROL_POINT_LIST',
  AssessmentControlPoint = 'ASSESSMENT_CONTROL_POINT',
  AssessmentControlPointAnswer = 'ASSESSMENT_CONTROL_POINT_ANSWER',
  AssessmentControlPointBreadcrumbs = 'ASSESSMENT_CONTROL_POINT_BREADCRUMBS',
  AssessmentControlPointComment = 'ASSESSMENT_CONTROL_POINT_COMMENT',
  AssessmentControlPointCultivations = 'ASSESSMENT_CONTROL_POINT_CULTIVATIONS',
  AssessmentControlPointEvidence = 'ASSESSMENT_CONTROL_POINT_EVIDENCE',
  AssessmentControlPointEvidenceFilter = 'ASSESSMENT_CONTROL_POINT_EVIDENCE_FILTER',
  AssessmentControlPointNonConformities = 'ASSESSMENT_CONTROL_POINT_NON_CONFORMITIES',
  AssessmentControlPointInputs = 'ASSESSMENT_CONTROL_POINT_INPUTS',
  AssessmentExports = 'ASSESSMENT_EXPORTS',
  AssessmentModuleSidebar = 'ASSESSMENT_MODULE_SIDEBAR',
  AssessmentPlotCultivations = 'ASSESSMENT_PLOT_CULTIVATIONS',
  AssessmentPreliminaryQuestionAnswer = 'ASSESSMENT_PRELIMINARY_QUESTION_ANSWER',
  AssessmentProgress = 'ASSESSMENT_PROGRESS',
  AssessmentReports = 'ASSESSMENT_REPORT',
  AssessmentStandardRequest = 'ASSESSMENT_STANDARD_REQUEST',
  AssessmentUserPermission = 'ASSESSMENT_USER_PERMISSION',
  Auth = 'AUTH',
  CamundaProcessDefinitionList = 'CAMUNDA_PROCESS_DEFINITION_LIST',
  CamundaTask = 'CAMUNDA_TASK',
  CamundaTaskDefinitionList = 'CAMUNDA_TASK_DEFINITION_LIST',
  CamundaTaskList = 'CAMUNDA_TASK_LIST',
  Category = 'CATEGORY',
  CbAssessmentMetadata = 'CB_ASSESSMENT_METADATA',
  ConnectedAssessment = 'CONNECTED_ASSESSMENT',
  ControlPoint = 'CONTROL_POINT',
  ControlPointCriticality = 'CONTROL_POINT_CRITICALITY',
  ControlPointProductionTechnique = 'CONTROL_POINT_PRODUCTION_TECHNIQUE',
  ControlPointVerificationMethod = 'CONTROL_POINT_VERIFICATION_METHOD',
  CertificationBodies = 'CERTIFICATION_BODIES',
  Evidence = 'EVIDENCE',
  EvidenceType = 'EVIDENCE_TYPE',
  HelpDocument = 'HELP_DOCUMENT',
  HelpDocumentType = 'HELP_DOCUMENT_TYPE',
  Module = 'MODULE',
  NonConformityAndCorrectiveAction = 'NON_CONFORMITY_AND_CORRECTIVE_ACTION',
  Organization = 'ORGANIZATION',
  OrganizationClientIdentifiers = 'ORGANIZATION_CLIENT_IDENTIFIERS',
  OrganizationCultivation = 'ORGANIZATION_CULTIVATION',
  OrganizationPlotCultivation = 'ORGANIZATION_PLOT_CULTIVATION',
  OrganizationInvitations = 'ORGANIZATION_INVITATIONS',
  OrganizationMember = 'ORGANIZATION_MEMBER',
  OrganizationTypeRoles = 'ORGANIZATION_TYPE_ROLES',
  PossibleAnswer = 'POSSIBLE_ANSWER',
  PreliminaryQuestion = 'PRELIMINARY_QUESTION',
  PossibleAnswerSet = 'POSSIBLE_ANSWER_SET',
  Product = 'PRODUCT',
  Retailers = 'RETAILERS',
  Standard = 'STANDARD',
  StandardSetterEvidence = 'STANDARD_SETTER_EVIDENCE',
  Subscriptions = 'SUBSCRIPTIONS',
  UserProfile = 'USER_PROFILE',
}
